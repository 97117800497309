import React from 'react';
import Paper from '@mui/material/Paper';
import { COMPONENT_PADDING } from '../../../themes/theme';
import { UserData, UsersData } from '../../../model/backendDataModels';
import UserDetails from './UserDetails';
import { CustomerItemType } from '../../../model/frontendDataModels';

type Props = {
  user: UserData;
  selectedCustomer: CustomerItemType;
  usersData: UsersData;
  close: () => void;
  handleSave: (user: UserData) => Promise<void>;
  handleDeleteUser: (email: string) => Promise<boolean>;
};

export default function AddOrEditUser({ user, selectedCustomer, usersData, close, handleSave, handleDeleteUser }: Props): JSX.Element {
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        borderRight: '2px solid black',
        padding: `${COMPONENT_PADDING}px`,
      }}
    >
      <UserDetails
        user={user}
        selectedCustomer={selectedCustomer}
        close={close}
        usersData={usersData}
        handleSave={handleSave}
        handleDeleteUser={handleDeleteUser}
      />
    </Paper>
  );
}
