export function convertTemperature(celsius: number, displayFahrenheit: boolean, precision: number): number {
  if (displayFahrenheit) {
    const convertedTemperature = parseFloat((celsius * 1.8 + 32).toFixed(precision));

    return convertedTemperature;
  } else {
    const convertedTemperature = parseFloat((celsius * 1).toFixed(precision));

    return convertedTemperature;
  }
}

export function toTemperatureString(celsius: number, displayFahrenheit: boolean, precision: number): string {
  const convertedTemperature = convertTemperature(celsius, displayFahrenheit, precision);

  return `${convertedTemperature} °${displayFahrenheit ? 'F' : 'C'}`;
}

export function getTemperatureUnit(displayFahrenheit: boolean): string {
  return `°${displayFahrenheit ? 'F' : 'C'}`;
}
