import { SWRResponse } from 'swr';
import { UsersData, User, InvoiceDataInvAdmin, InvoiceDataSysAdmin } from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';
import { sendEmptyPostRequest, sendPatchRequest } from '../utils/dataSenders';

export function useUser(accessToken: string | null): SWRResponse<User, BackendError> {
  const fullUrl = getFullUrl('/user');
  return getUseSwr<User>(fullUrl, accessToken);
}

export type PatchUserData = {
  timezone: string;
  temperatureFormat: 'fahrenheit' | 'celcius';
};

export async function editUserConfig(
  data: PatchUserData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    return await sendPatchRequest({ url: '/user/config', data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export async function acceptEula(token: string, addBackendError: (newError: BackendError) => void): Promise<boolean> {
  try {
    return await sendEmptyPostRequest({ url: '/user/eula/accept', token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export function useUsers(accessToken: string | null): SWRResponse<UsersData, BackendError> {
  const fullUrl = getFullUrl('/users');
  return getUseSwr<UsersData>(fullUrl, accessToken);
}

export function useInvoiceData(accessToken: string | null): SWRResponse<InvoiceDataInvAdmin | InvoiceDataSysAdmin, BackendError> {
  const fullUrl = getFullUrl('/subscription');
  return getUseSwr<InvoiceDataInvAdmin | InvoiceDataSysAdmin>(fullUrl, accessToken);
}
