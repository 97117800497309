import React from 'react';
import { useTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

type ProgressBarProps = {
  percent: number;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';
};

export default function ProgressBar({ percent, color }: ProgressBarProps): JSX.Element {
  const theme = useTheme();

  return (
    <LinearProgress
      variant='determinate'
      value={percent}
      color={color ?? 'info'}
      sx={{ borderRadius: 4, height: '8px', backgroundColor: theme.palette.secondary.main }}
    />
  );
}
