import { FleetItemType } from '../../../model/frontendDataModels';
import { MapRef } from 'react-map-gl';
import mapboxgl, { Map as MapGl } from 'mapbox-gl';

export type SelectableMarker = {
  lat: number;
  lng: number;
  fleetItem: FleetItemType;
  expandItems: string[];
  state: string;
};

export function updateMapData(currentMap: mapboxgl.Map, markers: SelectableMarker[]): void {
  (currentMap.getSource('markers') as mapboxgl.GeoJSONSource).setData({
    type: 'FeatureCollection',
    features: markers.map(marker => ({
      id: marker.fleetItem.fleetId,
      type: 'Feature',
      properties: { ...marker },
      geometry: {
        type: 'Point',
        coordinates: [marker.lng, marker.lat],
      },
    })),
  });
}

export function updateMap(currentMap: MapRef, selectableMarkers: SelectableMarker[], width: number): void {
  try {
    if (selectableMarkers.length > 1) {
      const locations = selectableMarkers.map(selectableMarker => {
        return { lat: selectableMarker.lat, lng: selectableMarker.lng };
      });
      const bounds = mapboxgl.LngLatBounds.convert([
        Math.min(...locations.map(l => l.lng)),
        Math.min(...locations.map(l => l.lat)),
        Math.max(...locations.map(l => l.lng)),
        Math.max(...locations.map(l => l.lat)),
      ]);
      // Adjust the padding if the map is really small
      const padding = width > 160 ? 80 : width / 4;

      currentMap.fitBounds(bounds, { maxZoom: 15, padding: padding });
    } else if (selectableMarkers.length === 1) {
      currentMap.flyTo({ center: { lat: selectableMarkers[0].lat, lon: selectableMarkers[0].lng }, zoom: 15 });
    } else {
      currentMap.flyTo({ center: { lat: 52.871956, lon: -1.419883 }, zoom: 0 });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(error));
  }
}

export function handleMapLoad(
  currentMapRef: MapRef,
  selectableMarkers: SelectableMarker[],
  width: number,
  markerSelectionCallback: (selectableMarker: SelectableMarker) => void
): void {
  const warningTextColor = '#E5E5E5';
  const inactiveTextColor = '#000000';
  const defaultTextColor = '#E5E5E5';
  const warningColor = '#b32626';
  const inactiveColor = '#D5D5D5';
  const activeColor = '#00995c';

  if (currentMapRef === undefined) {
    return;
  }

  const currentMap: MapGl = currentMapRef.getMap();

  const inactiveCase = ['==', ['get', 'state'], 'inactive'];
  const activeCase = ['==', ['get', 'state'], 'active'];
  const warningCase = ['==', ['get', 'state'], 'warning'];
  const source: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: selectableMarkers.map(marker => ({
        id: marker.fleetItem.fleetId,
        type: 'Feature',
        properties: { ...marker },
        geometry: {
          type: 'Point',
          coordinates: [marker.lng, marker.lat],
        },
      })),
    },
    clusterRadius: 42,
    cluster: true,
    clusterProperties: {
      inactive: ['+', ['case', inactiveCase, 1, 0]],
      active: ['+', ['case', activeCase, 1, 0]],
      warning: ['+', ['case', warningCase, 1, 0]],
    },
  };

  try {
    if (currentMap.getLayer('clusters')) {
      currentMap.removeLayer('clusters');
    }
    if (currentMap.getLayer('cluster-count')) {
      currentMap.removeLayer('cluster-count');
    }
    if (currentMap.getLayer('unclustered-point')) {
      currentMap.removeLayer('unclustered-point');
    }

    currentMap.removeSource('markers');
  } catch (error) {
    // No error, this will happen first time we load the map
  }

  currentMap.addSource('markers', source);

  if (!currentMap.getLayer('clusters')) {
    currentMap.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'markers',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'case',
          ['>', ['get', 'warning'], 0],
          warningColor,
          ['case', ['>', ['get', 'active'], 0], activeColor, inactiveColor],
        ],
        'circle-stroke-color': [
          'case',
          ['>', ['get', 'warning'], 0],
          warningColor,
          ['case', ['>', ['get', 'active'], 0], activeColor, inactiveColor],
        ],
        'circle-stroke-opacity': 0.7,
        'circle-stroke-width': 6,
        'circle-radius': 15,
      },
    });

    // inspect a cluster on click
    currentMap.on('click', 'clusters', e => {
      const features = currentMap.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      });

      if (features.length < 1) {
        return;
      }

      const cluster = features[0];

      const clusterId = cluster.properties?.cluster_id;
      const pointCount = cluster.properties?.point_count;
      const markers: mapboxgl.GeoJSONSource = currentMap.getSource('markers') as mapboxgl.GeoJSONSource;

      markers.getClusterLeaves(clusterId, pointCount, 0, (err, leaves) => {
        if (leaves.length > 1) {
          const locations = leaves.map(l => l.geometry as GeoJSON.Point);
          const bounds = mapboxgl.LngLatBounds.convert([
            Math.min(...locations.map(l => l.coordinates[0])),
            Math.min(...locations.map(l => l.coordinates[1])),
            Math.max(...locations.map(l => l.coordinates[0])),
            Math.max(...locations.map(l => l.coordinates[1])),
          ]);

          currentMap.fitBounds(bounds, { padding: 80 });
        }
      });
    });

    currentMap.on('mouseenter', 'clusters', () => {
      currentMap.getCanvas().style.cursor = 'pointer';
    });

    currentMap.on('mouseleave', 'clusters', () => {
      currentMap.getCanvas().style.cursor = '';
    });

    currentMap.on('mouseenter', 'unclustered-point', () => {
      currentMap.getCanvas().style.cursor = 'pointer';
    });

    currentMap.on('mouseleave', 'unclustered-point', () => {
      currentMap.getCanvas().style.cursor = '';
    });
  }

  if (!currentMap.getLayer('cluster-count')) {
    currentMap.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'markers',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 14,
      },
      paint: {
        'text-color': [
          'case',
          ['>', ['get', 'warning'], 0],
          warningTextColor,
          ['case', ['>', ['get', 'active'], 0], defaultTextColor, inactiveTextColor],
        ],
      },
    });

    currentMap.on('zoomstart', () => {
      currentMap.setLayoutProperty('cluster-count', 'visibility', 'none');
    });

    currentMap.on('zoomend', () => {
      currentMap.setLayoutProperty('cluster-count', 'visibility', 'visible');
    });
  }

  if (!currentMap.getLayer('unclustered-point')) {
    currentMap.addLayer({
      id: 'unclustered-point',
      type: 'symbol',
      source: 'markers',
      layout: {
        'icon-image': ['match', ['get', 'state'], 'inactive', 'map-pin-inactive', 'warning', 'map-pin-warning', 'map-pin-active'],
        'icon-size': 1,
      },
      filter: ['!', ['has', 'point_count']],
    });

    currentMap.on('click', 'unclustered-point', e => {
      if (!e.features || e.features.length === 0) {
        return;
      }

      const feature = e.features[0];
      const properties = feature.properties as unknown as SelectableMarker;

      markerSelectionCallback(properties);
    });
  }

  updateMap(currentMapRef, selectableMarkers, width);
}
