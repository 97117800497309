import { Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { SxProps as MuiSxProps } from '@mui/material';
import { scrollbarTheme } from './scrollbarTheme';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    tableText: React.CSSProperties;
    deviceInfoText: React.CSSProperties;
    highlightText: React.CSSProperties;
    columnHeader: React.CSSProperties;
    bigHeader: React.CSSProperties;
    sleekHeader: React.CSSProperties;
    bigText: React.CSSProperties;
    graphHeader: React.CSSProperties;
    sectionHeader: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    tableText?: React.CSSProperties;
    deviceInfoText?: React.CSSProperties;
    highlightText?: React.CSSProperties;
    errorText?: React.CSSProperties;
    columnHeader?: React.CSSProperties;
    bigHeader?: React.CSSProperties;
    sleekHeader?: React.CSSProperties;
    bigText?: React.CSSProperties;
    graphHeader?: React.CSSProperties;
    sectionHeader?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tableText: true;
    deviceInfoText: true;
    highlightText: true;
    errorText: true;
    columnHeader: true;
    bigHeader: true;
    sleekHeader: true;
    bigText: true;
    graphHeader: true;
    sectionHeader: true;

    h1: false;
    h2: false;
    h3: false;
    h4: false;
    h5: false;
    h6: false;
    body1: false;
    body2: false;
    button: false;
    caption: false;
    overline: false;
  }
}

export const getTheme = createTheme(scrollbarTheme, {
  palette: {
    primary: {
      main: '#1F282D',
    },
    secondary: {
      main: '#151E23',
    },
    error: {
      main: '#DC2F2F',
    },
    warning: {
      main: '#E77823',
    },
    success: {
      main: '#00BA70',
    },
    info: {
      main: '#00A3FF',
      dark: '#0488d3',
    },
    action: {
      active: '#7297AC',
      selected: '#000000',
      disabled: '#7297AC',
      disabledBackground: '#7297AC',
      focus: 'inherit', // @note ugly bugfix
      hover: '#2B3338',
    },
    background: {
      default: '#151d21',
      paper: '#1f282d',
    },
    text: {
      primary: '#D5D5D5',
      disabled: '#808B8B',
      secondary: '#7297AC',
    },
  },
  typography: {
    tableText: {
      fontFamily: 'Helvetica',
      fontSize: '13px',
      color: '#D5D5D5',
    },
    deviceInfoText: {
      fontFamily: 'Helvetica',
      fontSize: '12px',
      color: '#D5D5D5',
    },
    highlightText: {
      fontFamily: 'Helvetica',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#D5D5D5',
    },
    errorText: {
      fontFamily: 'Helvetica',
      fontSize: '12px',
      fontWeight: 'italic',
      color: '#ED4337',
    },
    columnHeader: {
      fontFamily: 'Helvetica',
      fontSize: '14px',
      fontStyle: 'italic',
      color: '#7297AC',
    },
    bigHeader: {
      fontFamily: 'Helvetica',
      fontSize: '27px',
      fontWeight: 'bold',
      color: '#D5D5D5',
    },
    sleekHeader: {
      fontFamily: 'Helvetica',
      fontSize: '25px',
      color: '#D5D5D5',
    },
    bigText: {
      fontFamily: 'Helvetica',
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#D5D5D5',
    },
    graphHeader: {
      fontFamily: 'Helvetica',
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#D5D5D5',
    },
    sectionHeader: {
      fontFamily: 'Helvetica',
      fontSize: '14px',
      textTransform: 'uppercase',
      color: '#7297AC',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          cursor: 'default',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#00A3FF',
          height: 2,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          backgroundColor: '#1F282D',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          marginRight: 10,
          overflow: 'hidden',
          textTransform: 'capitalize',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#D5D5D5',
          '&.Mui-checked': {
            color: '#D5D5D5 ',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: '#00A3FF',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#0080CC',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          backgroundColor: '#2B3338',
          border: '2px #0080CC solid',
          borderRadius: '5px',
          textTransform: 'none',
          fontFamily: 'Helvetica',
          fontSize: '24px',
          fontWeight: 'bold',
          color: '#D5D5D5',
          '&:hover': {
            backgroundColor: '#0080CC',
            border: '2px #0080CC solid',
            color: '#1F282D',
          },
          '&:disabled': {
            color: '#D5D5D5',
            backgroundColor: '#2B3338',
            opacity: '40%',
          },
        },
        contained: {
          backgroundColor: '#0080CC',
          textTransform: 'none',
          borderRadius: '5px',
          color: '#1F282D',
          fontFamily: 'Helvetica',
          fontSize: '14px',
          fontWeight: 'bold',
          '&:hover': {
            color: '#1F282D',
            backgroundColor: '#00A3FF',
          },
          '&:disabled': {
            color: '#1F282D',
            backgroundColor: '#0080CC',
            opacity: '40%',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: '#00BA70',
          color: 'white',
        },
        standardError: {
          backgroundColor: '#DC2F2F',
          color: 'white',
        },
        standardWarning: {
          backgroundColor: '#E77823',
          color: 'white',
        },
        standardInfo: {
          backgroundColor: '#00A3FF',
          color: 'black',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        row: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        root: {
          '--DataGrid-rowBorderColor': '#30444E',
          border: 'none',
          font: '400 0.875rem Roboto, Helvetica, Arial, sans-serif !important',
          '& .MuiOutlinedInput-root': {
            fontSize: '0.875rem',
          },
        },
        withBorderColor: {
          borderColor: '#30444E',
        },
        cell: {
          display: 'flex',
          alignItems: 'center',
        },
        columnHeader: {
          '--DataGrid-rowBorderColor': 'transparent',
          color: '#7297AC',
          fontStyle: 'italic',
          fontSize: '14px',
          fontFamily: 'Helvetica',
          background: '#1F282D',
        },
        columnSeparator: {
          display: 'none',
        },
        filler: {
          visibility: 'hidden',
        },
      },
    },
  },
});

export const WIDTH_OF_INFO_COMPONENT = 520;
export const COMPONENT_PADDING = 24;

type createSxParams = {
  tinySx?: MuiSxProps;
  smallSx?: MuiSxProps;
  mediumSx?: MuiSxProps;
  largeSx?: MuiSxProps;
};

export function createSx(theme: Theme, { tinySx, smallSx, mediumSx, largeSx }: createSxParams): MuiSxProps {
  return {
    // prefer the large setting, but fall back to smaller settings if they were given
    [theme.breakpoints.only('xl')]: {
      ...tinySx,
      ...smallSx,
      ...mediumSx,
      ...largeSx,
    },
    // for all the smaller settings: use it if it's given, but fall back to the larger settings otherwise
    [theme.breakpoints.only('lg')]: {
      ...largeSx,
      ...mediumSx,
    },
    [theme.breakpoints.only('md')]: {
      ...largeSx,
      ...mediumSx,
      ...smallSx,
    },
    [theme.breakpoints.down('md')]: {
      ...largeSx,
      ...mediumSx,
      ...smallSx,
      ...tinySx,
    },
    // @note it may be possible to write this cleaner, but this will do for now / jespo 2021-12-08
  };
}

export const CHART_COLORS = [
  '#0398fc',
  '#03fc98',
  '#fc0398',
  '#fc9803',
  '#98fc03',
  '#98036c',
  '#13185c',
  '#23284c',
  '#33383c',
  '#43482c',
  '#53581c',
];
