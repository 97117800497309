import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { Checkbox, CircularProgress, Link, Typography } from '@mui/material';
import FormDialog from '../common/FormDialog';
import { AppContext } from '../../App';
import { LoginContext } from '../../Login';
import { getFullUrl } from '../../utils/getFullUrl';
import { BackendError } from '../../utils/BackendError';

type EulaPopupProps = {
  open: boolean;
  close: (ok: boolean) => Promise<void>;
};

export default function EulaPopup(props: EulaPopupProps): JSX.Element | null {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [agree, setAgree] = useState<boolean>(false);
  const [downloadingEula, setDownloadingEula] = useState(false);

  const downloadEula = async (): Promise<void> => {
    const fullUrl = getFullUrl('/legal/document/eula');

    setDownloadingEula(true);

    try {
      const response = await fetch(fullUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${loginContext.accessToken}`,
        },
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'EULA.pdf');
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);

      link.click();
      link.remove();
    } catch (error) {
      appContext.addBackendError(error as BackendError);
    }

    setDownloadingEula(false);
  };

  return (
    <FormDialog
      open={props.open}
      close={async (ok: boolean): Promise<void> => {
        await props.close(ok);
        setAgree(false);
      }}
      title='End User License Agreement'
      okButton='Accept'
      okDisabled={!agree ? true : undefined}
    >
      <Box sx={{ marginTop: '20px' }}>
        <Typography variant='tableText'>
          Before you can proceed and use the service, you must accept the End User License Agreement. Please review the agreement carefully.
          By accepting, you agree to the terms and conditions set forth in the agreement.
        </Typography>
      </Box>
      <Box sx={{ marginTop: '14px' }}>
        <Typography variant='tableText'>
          <Box>
            I have read and accept the{' '}
            <Link component='button' onClick={downloadEula}>
              End User License Agreement
            </Link>
            <Checkbox checked={agree} onChange={(): void => setAgree(!agree)} />
            {downloadingEula && <CircularProgress sx={{ verticalAlign: 'middle' }} size={18} />}
          </Box>
        </Typography>
      </Box>
    </FormDialog>
  );
}
