import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { ChartOptions } from 'chart.js';
import MultiAxisBarChart from './MultiAxisBarChart';
import MultiAxisLineChart from './MultiAxisLineChart';
import moment from 'moment-timezone';
import { AppContext } from '../../../../../../App';

function getOptions(
  axisType: AxisType,
  title: string,
  leftAxisOptions: string[],
  rightAxisOptions: string[]
): ChartOptions<'bar' | 'line'> {
  const options: ChartOptions<'bar' | 'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      zoom: {
        limits: {
          x: { min: 'original', max: 'original' },
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          wheel: {
            enabled: true,
            speed: 0.1,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
      title: {
        display: true,
        text: title,
      },
    },
    scales: {
      left:
        leftAxisOptions.length > 0
          ? {
              type: 'linear',
              display: true,
              position: 'left' as const,
              grid: {
                drawOnChartArea: false,
              },
              beginAtZero: true,
            }
          : undefined,
      right:
        rightAxisOptions.length > 0
          ? {
              type: 'linear',
              display: true,
              position: 'right' as const,
              grid: {
                drawOnChartArea: false,
              },
              beginAtZero: true,
            }
          : undefined,
    },
  };

  if (axisType === 'Category') {
    if (options.scales) {
      options.scales.x = {
        type: 'category',
        ticks: {
          autoSkip: true,
          maxTicksLimit: 15,
        },
        title: {
          display: false,
        },
      };
    }
  } else if (axisType === 'Time') {
    if (options.scales) {
      options.scales.x = {
        type: 'time',
        time: {
          parser: 'date-fns',
          displayFormats: {
            year: 'yyyy-MM-dd',
            quarter: 'yyyy-MM-dd',
            month: 'yyyy-MM-dd',
            week: 'yyyy-MM-dd',
            day: 'yyyy-MM-dd',
            hour: 'yyyy-MM-dd HH:mm',
            minute: 'yyyy-MM-dd HH:mm',
            second: 'yyyy-MM-dd HH:mm',
            millisecond: 'yyyy-MM-dd HH:mm:ss',
          },
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
        },
        title: {
          display: false,
        },
      };
    }
  }
  return options;
}

export type AxisType = 'Category' | 'Time';
export const AxisTypes = ['Category', 'Time'];

type Props = {
  title: string;
  chartType: 'Line' | 'Bar';
  axisType: AxisType;
  xValues: moment.Moment[];
  data: number[][];
  allOptions: string[];
  leftAxisOptions: string[];
  rightAxisOptions: string[];
};

export default function MultiAxisChart({
  title,
  chartType,
  axisType,
  xValues,
  data,
  allOptions,
  leftAxisOptions,
  rightAxisOptions,
}: Props): JSX.Element {
  const appContext = useContext(AppContext);

  const options = getOptions(axisType, title, leftAxisOptions, rightAxisOptions);

  return (
    <Box sx={{ minHeight: '85%', paddingTop: '1em' }}>
      {chartType === 'Line' && (
        <MultiAxisLineChart
          xValues={
            axisType === 'Category'
              ? xValues.map(date => date.tz(appContext.timezone).format('YYYY-MM-DD HH:mm'))
              : xValues.map(date => date.tz(appContext.timezone).toDate())
          }
          data={data}
          allOptions={allOptions}
          leftAxisOptions={leftAxisOptions}
          rightAxisOptions={rightAxisOptions}
          options={options}
        />
      )}
      {chartType === 'Bar' && (
        <MultiAxisBarChart
          xValues={
            axisType === 'Category'
              ? xValues.map(date => date.tz(appContext.timezone).format('YYYY-MM-DD HH:mm'))
              : xValues.map(date => date.toDate())
          }
          data={data}
          allOptions={allOptions}
          leftAxisOptions={leftAxisOptions}
          rightAxisOptions={rightAxisOptions}
          options={options}
        />
      )}
    </Box>
  );
}
