import React, { useContext, useState } from 'react';
import Paper from '@mui/material/Paper';
import { COMPONENT_PADDING } from '../../../themes/theme';
import { Box } from '@mui/material';
import { PostUser, UserData } from '../../../model/backendDataModels';
import ListUsers from './ListUsers';
import { AppContext } from '../../../App';
import { useUsers } from '../../../dataHooks/adminHooks';
import { addUser, deleteUser, editUser } from '../../../services/userManipulation';
import { LoginContext } from '../../../Login';
import LoadingIndicator from '../../common/LoadingIndicator';
import DefaultButton from '../../common/DefaultButton';
import { CustomerItemType } from '../../../model/frontendDataModels';
import CustomerSelector from '../common/CustomerSelector';
import AddOrEditUser from './AddOrEditUser';

const NEW_USER: UserData = {
  email: '',
  customerId: '',
  roleId: 0,
};

export default function UserHandlingTab(): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [addOrEditUser, setAddOrEditUser] = useState<UserData | undefined>(undefined);

  const [selectedCustomer, setSelectedCustomer] = useState<CustomerItemType | undefined>(undefined);

  const { data: usersData, error, mutate } = useUsers(loginContext.accessToken);

  if (error) {
    appContext.addBackendError(error);
  }

  async function handleDeleteUser(email: string): Promise<boolean> {
    const result = await deleteUser(email, loginContext.accessToken || '', appContext.addBackendError);
    if (result) {
      mutate();
    }
    return result;
  }

  async function handleSave(user: UserData): Promise<void> {
    if (loginContext.accessToken) {
      if (addOrEditUser === NEW_USER) {
        const result = await addUser(user, loginContext.accessToken, appContext.addBackendError);
        if (result) {
          setAddOrEditUser(user);
          if (usersData) {
            const newUsersData = {
              ...usersData,
              users: [...usersData.users, user],
            };
            mutate(newUsersData);
          }
        }
      } else {
        const postUser: PostUser = {
          roleId: user.roleId,
          customerId: user.customerId,
        };
        const result = await editUser(user.email, postUser, loginContext.accessToken, appContext.addBackendError);
        if (result) {
          setAddOrEditUser(user);
          mutate();
        }
      }
    }
  }

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        minHeight: '100%',
        borderTop: '2px solid black',
      }}
    >
      <Box component='div'>
        <CustomerSelector
          selectedCustomers={selectedCustomer ? [selectedCustomer] : []}
          updateSelectCustomers={(customers: CustomerItemType[]): void => {
            if (customers.length > 0) {
              setSelectedCustomer(customers[0]);
            } else {
              setSelectedCustomer(undefined);
            }
          }}
        />
      </Box>
      {!addOrEditUser && usersData && selectedCustomer && (
        <Paper elevation={0} sx={{ width: '100%', height: '100%', padding: `${COMPONENT_PADDING}px` }}>
          <DefaultButton
            sx={{ marginTop: '5px' }}
            onClick={(): void => {
              setAddOrEditUser(NEW_USER);
            }}
          >
            Add User
          </DefaultButton>
          <Box sx={{ marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
            {<ListUsers usersData={usersData} customer={selectedCustomer} setEditUser={setAddOrEditUser} />}
          </Box>
        </Paper>
      )}
      {!addOrEditUser && !usersData && <LoadingIndicator />}
      {addOrEditUser !== undefined && usersData && selectedCustomer && (
        <AddOrEditUser
          user={addOrEditUser}
          usersData={usersData}
          handleSave={handleSave}
          handleDeleteUser={handleDeleteUser}
          close={(): void => {
            setAddOrEditUser(undefined);
          }}
          selectedCustomer={selectedCustomer}
        />
      )}
    </Box>
  );
}
