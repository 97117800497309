import React from 'react';
import { COMPONENT_PADDING } from '../../../themes/theme';
import Typography from '@mui/material/Typography';
import ExportLogsView from './ExportLogsView';
import { Grid, Paper } from '@mui/material';
import DeviceListView from './DeviceListView';
import Box from '@mui/material/Box';
import { BackendFleet, BackendFleetgroup } from '../../../model/backendDataModels';

type FleetOrGroupOverviewInfoProps = {
  chargerCount: number;
  batteryCount: number;
  gatewayCount: number;
  selectedFleetOrFleetgroup: BackendFleet | BackendFleetgroup;
};

export default function FleetOrGroupOverviewInfo({
  chargerCount,
  batteryCount,
  gatewayCount,
  selectedFleetOrFleetgroup,
}: FleetOrGroupOverviewInfoProps): JSX.Element {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
      <Paper sx={{ padding: `${COMPONENT_PADDING}px` }}>
        <Typography variant='bigHeader' component='div'>
          Overview
        </Typography>
        <Grid container spacing={`${COMPONENT_PADDING}px`}>
          <DeviceListView chargerCount={chargerCount} batteryCount={batteryCount} gatewayCount={gatewayCount} />
          <ExportLogsView selectedFleetOrFleetgroup={selectedFleetOrFleetgroup} maxInterval={92}></ExportLogsView>
        </Grid>
      </Paper>
    </Box>
  );
}
