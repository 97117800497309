import React, { useEffect, useState } from 'react';
import { Box, Button, MenuItem, Select, SelectChangeEvent, Stack, useTheme } from '@mui/material';
import { UserData, UsersData } from '../../../model/backendDataModels';
import InputItem from '../../common/InputItem';
import { CustomerItemType } from '../../../model/frontendDataModels';
import LoadingIndicator from '../../common/LoadingIndicator';
import DeleteDialog from '../../common/DeleteDialog';
import { Delete } from '@mui/icons-material';
import DefaultButton from '../../common/DefaultButton';

type Props = {
  user: UserData;
  selectedCustomer: CustomerItemType;
  close: () => void;
  usersData: UsersData;
  handleSave: (user: UserData) => Promise<void>;
  handleDeleteUser: (email: string) => Promise<boolean>;
};

export default function UserDetails({ user, selectedCustomer, close, usersData, handleSave, handleDeleteUser }: Props): JSX.Element {
  const [sendingData, setSendingData] = useState<boolean>(false);

  const [email, setEmail] = useState<string>(user ? user.email : '');
  const [roleId, setRoleId] = useState<number>(user ? user.roleId : 0);

  const [changesHasBeenMade, setChangesHasBeenMade] = useState<boolean>(false);

  const [deletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);

  useEffect(() => {
    setChangesHasBeenMade(user.email !== email || selectedCustomer.customerId !== user.customerId || roleId !== user.roleId);
  }, [selectedCustomer, email, roleId]);

  const theme = useTheme();

  async function saveUser(): Promise<void> {
    setSendingData(true);
    const userToSave: UserData = {
      email: email.trim(),
      roleId,
      customerId: selectedCustomer.customerId,
    };
    await handleSave(userToSave);
    setSendingData(false);
    close();
  }

  function handleClose(): void {
    close();
  }

  return (
    <Stack direction='column' spacing={3} sx={{ width: '400px', marginBottom: '10px' }}>
      <InputItem label='Email' value={email} updateValue={setEmail} />

      <Select
        value={roleId.toString()}
        sx={{
          height: '40px',
          backgroundColor: theme.palette.background.default,
          borderRadius: '5px',
          '&:before': {
            borderRadius: '5px',
          },
        }}
        onChange={(event: SelectChangeEvent<string>): void => {
          setRoleId(parseInt(event.target.value));
        }}
      >
        {Object.keys(usersData.roles)
          .filter(roleId => roleId !== '10')
          .map((roleId, index) => (
            <MenuItem key={index} value={roleId} sx={{ height: '40px' }}>
              {usersData.roles[roleId]}
            </MenuItem>
          ))}
      </Select>

      {!sendingData && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <DefaultButton onClick={handleClose}>Cancel</DefaultButton>
            <DefaultButton onClick={saveUser} disabled={!changesHasBeenMade || email === '' || roleId === 0}>
              Save
            </DefaultButton>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end' }}>
            <Button
              sx={{ color: 'error' }}
              variant='contained'
              onClick={(): void => {
                setDeletePopupOpen(true);
              }}
              disabled={user.email === ''}
              endIcon={<Delete />}
            >
              Delete
            </Button>
          </Box>
        </>
      )}
      {sendingData && <LoadingIndicator />}
      <DeleteDialog
        nameToDelete={user.email}
        close={async (ok: boolean): Promise<void> => {
          if (ok) {
            const result = await handleDeleteUser(user.email);
            if (result) {
              close();
            }
          }
          setDeletePopupOpen(false);
        }}
        open={deletePopupOpen}
      />
    </Stack>
  );
}
