import { mutate, SWRResponse } from 'swr';
import {
  AggregatedAlarms,
  BackendFleet,
  BackendFleetgroup,
  BackendFleetgroupInfo,
  BackendFleetInfo,
  BackendFleetLocation,
  CalendarStateData,
  FieldsAndNumberValues,
  FieldsAndValues,
  LineChartData,
  ListTabs,
} from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';
import { Moment } from 'moment-timezone';

export function useListTabs(type: 'fleet' | 'fleetgroup', id: string, accessToken: string | null): SWRResponse<ListTabs, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/listTabs`);

  return getUseSwr<ListTabs>(fullUrl, accessToken);
}

export function useChargingCompletionDaily(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<CalendarStateData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/charging/daily`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<CalendarStateData>(fullUrl, accessToken);
}

export function useChargingCompletionSum(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<LineChartData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/charging/sum`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<LineChartData>(fullUrl, accessToken);
}

export function useEqualizingCompletion(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<LineChartData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/equalizing/completion`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<LineChartData>(fullUrl, accessToken);
}

export function useSocDeepDischargeDaily(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<CalendarStateData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/soc/daily`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<CalendarStateData>(fullUrl, accessToken);
}

export function useSocDeepDischargeSum(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<LineChartData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/soc/sum`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<LineChartData>(fullUrl, accessToken);
}

export function useAlarmsDaily(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<CalendarStateData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/alarm/daily`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<CalendarStateData>(fullUrl, accessToken);
}

export function useAlarmsAggregated(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<AggregatedAlarms, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/alarm/aggregated`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<AggregatedAlarms>(fullUrl, accessToken);
}

export function useAlarmLogs(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<FieldsAndValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/alarm/logs`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<FieldsAndValues>(fullUrl, accessToken);
}

export function useChargingLogs(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<FieldsAndValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/charging/logs`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<FieldsAndValues>(fullUrl, accessToken);
}

export function useEquilizingDaily(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<CalendarStateData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/equalizing/daily`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<CalendarStateData>(fullUrl, accessToken);
}

export function useEquilizingLogs(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<FieldsAndValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/equalizing/logs`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<FieldsAndValues>(fullUrl, accessToken);
}

export function useSocLogs(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<FieldsAndValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/soc/logs`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<FieldsAndValues>(fullUrl, accessToken);
}

export function useChargingMissed(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<FieldsAndNumberValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/charging/missed`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<FieldsAndNumberValues>(fullUrl, accessToken);
}

export function useEquilizingMissed(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<FieldsAndNumberValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/equalizing/missed`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<FieldsAndNumberValues>(fullUrl, accessToken);
}

export function useSocLowestSoc(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Moment,
  endDate: Moment,
  accessToken: string | null
): SWRResponse<FieldsAndNumberValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/soc/lowestsoc`, {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  });

  return getUseSwr<FieldsAndNumberValues>(fullUrl, accessToken);
}

export function useFleetgroups(
  parentType: string,
  parentId: string,
  accessToken: string | null
): SWRResponse<BackendFleetgroup[], BackendError> {
  const fullUrl = getFullUrl(`/${parentType}/${parentId}/fleetgroups`);

  return getUseSwr<BackendFleetgroup[]>(fullUrl, accessToken);
}

export function useFleetgroup(fleetgroupId: string | null, accessToken: string | null): SWRResponse<BackendFleetgroup, BackendError> {
  const fullUrl = fleetgroupId ? getFullUrl(`/fleetgroup/${fleetgroupId}`) : null;

  return getUseSwr<BackendFleetgroup>(fullUrl, accessToken);
}

export function useFleetgroupInfo(
  fleetgroupId: string | null,
  accessToken: string | null
): SWRResponse<BackendFleetgroupInfo, BackendError> {
  const fullUrl = fleetgroupId ? getFullUrl(`/fleetgroup/${fleetgroupId}/info`) : null;

  return getUseSwr<BackendFleetgroupInfo>(fullUrl, accessToken);
}

export function useFleets(parentType: string, parentId: string, accessToken: string | null): SWRResponse<BackendFleet[], BackendError> {
  const fullUrl = getFullUrl(`/${parentType}/${parentId}/fleets`);

  return getUseSwr<BackendFleet[]>(fullUrl, accessToken);
}

export function useFleet(fleetId: string | undefined, accessToken: string | null): SWRResponse<BackendFleet, BackendError> {
  const fullUrl = fleetId ? getFullUrl(`/fleet/${fleetId}`) : null;

  return getUseSwr<BackendFleet>(fullUrl, accessToken);
}

export function useFleetInfo(fleetId: string | null, accessToken: string | null): SWRResponse<BackendFleetInfo, BackendError> {
  const fullUrl = fleetId ? getFullUrl(`/fleet/${fleetId}/info`) : null;

  return getUseSwr<BackendFleetInfo>(fullUrl, accessToken);
}

export function useFleetLocations(
  customerId: string | undefined,
  accessToken: string | null
): SWRResponse<BackendFleetLocation[], BackendError> {
  const fullUrl = customerId ? getFullUrl(`/customer/${customerId}/fleet/locations`) : null;

  return getUseSwr<BackendFleetLocation[]>(fullUrl, accessToken);
}

export function mutateFleet(fleetId: string): Promise<void> {
  const fullUrl = getFullUrl(`/fleet/${fleetId}`);

  return mutate<void>(fullUrl);
}

export function mutateFleets(parentType: string, parentId: string): Promise<void> {
  const fullUrl = getFullUrl(`/${parentType}/${parentId}/fleets`);

  return mutate<void>(fullUrl);
}

export function mutateFleetLocations(customerId?: string): Promise<void> {
  const fullUrl = customerId ? getFullUrl(`/customer/${customerId}/fleet/locations`) : null;

  return mutate<void>(fullUrl);
}

export function mutateFleetgroups(parentType: string, parentId: string): Promise<void> {
  const fullUrl = getFullUrl(`/${parentType}/${parentId}/fleetgroups`);

  return mutate<void>(fullUrl);
}

export function mutateFleetgroup(fleetgroupId: string): Promise<void> {
  const fullUrl = getFullUrl(`/fleetgroup/${fleetgroupId}`);

  return mutate<void>(fullUrl);
}
