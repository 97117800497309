import React, { useContext } from 'react';
import LoadingIndicator from '../../../../../common/LoadingIndicator';
import ComponentBase from '../../../componentBase/ComponentBase';
import { Box } from '@mui/material';
import { SxProps } from '../../../../../common/commonTypes';
import calendarCreateMonth from '../../../calendarCreateMonth';
import CalendarWeekdaysHeader from '../../../CalendarWeekdaysHeader';
import { SWRResponse } from 'swr';
import { BackendError } from '../../../../../../utils/BackendError';
import { CalendarStateData } from '../../../../../../model/backendDataModels';
import { AppContext } from '../../../../../../App';
import MonthAndYearPicker from '../../../../../common/monthAndYearPicker/MonthAndYearPicker';
import CalendarStateDay from './CalendarStateDay';
import { Moment } from 'moment-timezone';

type StateCalendarProps = {
  year: number;
  month: number;
  data: CalendarStateData;
  selectedDate?: Date | null;
  onDateSelected?: (date: Date | null) => void;
};

function StateCalendar({ year, month, data, selectedDate, onDateSelected, sx }: StateCalendarProps & SxProps): JSX.Element {
  return (
    <Box sx={{ ...sx, display: 'flex', flexDirection: 'column', alignItems: 'space-between', paddingLeft: '40px', paddingRight: '40px' }}>
      <CalendarWeekdaysHeader />

      {calendarCreateMonth(year, month).map((week, weekIndex) => (
        <Box key={weekIndex} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          {week.map((point, dayIndex) => {
            const dateOfDay = new Date(year, month, point.day);
            const key = `${weekIndex}.${dayIndex}`;
            return dateOfDay.getMonth() === month ? (
              <CalendarStateDay
                date={dateOfDay}
                calendarState={data.length >= dateOfDay.getDate() ? data[dateOfDay.getDate() - 1] : null}
                key={key}
                selectedDate={selectedDate}
                onDateSelected={onDateSelected}
              />
            ) : (
              <CalendarStateDay
                date={dateOfDay}
                calendarState={null}
                outsideMonth
                key={key}
                selectedDate={selectedDate}
                onDateSelected={onDateSelected}
              />
            );
          })}
        </Box>
      ))}
    </Box>
  );
}

type DailyStateCalendarProps = {
  dataHook: (startDate: Moment, endDate: Moment) => SWRResponse<CalendarStateData, BackendError>;
  title: string;
  help?: React.ReactNode;
  selectedDate?: Date | null;
  startDate: Moment;
  endDate: Moment;
  onDateSelected?: (date: Date | null) => void;
  onYearSelected: (year: number) => void;
  onMonthSelected: (month: number) => void;
};

export default function DailyStateCalendar({
  dataHook,
  title,
  help,
  selectedDate,
  startDate,
  endDate,
  onDateSelected,
  onYearSelected,
  onMonthSelected,
}: DailyStateCalendarProps): JSX.Element {
  const appContext = useContext(AppContext);
  const { data, error, isLoading, isValidating } = dataHook(startDate, endDate);

  if (error) {
    appContext.addBackendError(error);
  }

  return (
    <ComponentBase title={title} help={help}>
      <MonthAndYearPicker
        year={startDate.year()}
        month={startDate.month()}
        onYearSelected={onYearSelected}
        onMonthSelected={onMonthSelected}
      />
      {!(isLoading || isValidating) && data && !error && (
        <StateCalendar
          year={startDate.year()}
          month={startDate.month()}
          data={data}
          selectedDate={selectedDate}
          onDateSelected={onDateSelected}
        />
      )}
      {(isLoading || isValidating) && <LoadingIndicator />}
    </ComponentBase>
  );
}
