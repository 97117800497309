import React from 'react';
import { IconButton } from '@mui/material';
import SortableList from '../../fleetView/common/SortableList';
import { UserData, UsersData } from '../../../model/backendDataModels';
import Icon from '../../common/Icon';
import { CustomerItemType } from '../../../model/frontendDataModels';

type ListUsersProps = {
  usersData: UsersData;
  customer: CustomerItemType;
  setEditUser: (user: UserData) => void;
};

export default function ListUsers(props: ListUsersProps): JSX.Element {
  const userTableHeaders = ['Email', 'Customer', 'Role', 'Edit'];

  if (!props.usersData.users) {
    return <></>;
  }

  const userTable = props.usersData.users
    .filter((user: UserData) => user.customerId === props.customer.customerId)
    .map((user: UserData) => {
      return [
        user.email,
        props.customer.name,
        props.usersData.roles[user.roleId],
        <>
          <IconButton
            color='info'
            onClick={(): void => {
              props.setEditUser(user);
            }}
          >
            <Icon name='edit' />
          </IconButton>
        </>,
      ];
    });

  return <SortableList sx={{ maxWidth: 900 }} headers={userTableHeaders} data={userTable} />;
}
