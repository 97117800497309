import { PostUser, UserData } from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { sendDeleteRequest, sendPostRequest } from '../utils/dataSenders';

export async function deleteUser(email: string, token: string, addBackendError: (newError: BackendError) => void): Promise<boolean> {
  try {
    const url = `/user/${email}/delete`;
    return await sendDeleteRequest({ url, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export async function editUser(
  email: string,
  data: PostUser,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    const url = `/user/${email}/edit`;
    return await sendPostRequest({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export async function addUser(data: UserData, token: string, addBackendError: (newError: BackendError) => void): Promise<boolean> {
  try {
    const url = '/user/add';
    return await sendPostRequest({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}
