import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { COMPONENT_PADDING } from '../../themes/theme';
import { FormControl, MenuItem, Paper, Select, SelectChangeEvent, Stack, Switch, useTheme } from '@mui/material';
import { timeZonesNames } from '@vvo/tzdb';
import SettingsIcon from '@mui/icons-material/Settings';
import { LoginContext } from '../../Login';
import { AppContext } from '../../App';
import { editUserConfig, useUser } from '../../dataHooks/adminHooks';

export function isFahrenheit(): boolean {
  const fahrenheitCountries = ['US', 'BS', 'BZ', 'KY', 'PW'];
  const locale = Intl.DateTimeFormat().resolvedOptions().locale.toLowerCase();

  for (const country of fahrenheitCountries) {
    if (locale.includes(country.toLowerCase())) {
      return true;
    }
  }

  return false;
}

function Settings(): JSX.Element {
  const defaultTemperatureMode = isFahrenheit() ? 'fahrenheit' : 'celcius';
  const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const theme = useTheme();

  const [timezone, setTimezone] = React.useState<string>(defaultTimezone);
  const [fahrenheit, setFahrenheit] = React.useState<boolean>(defaultTemperatureMode === 'fahrenheit');

  const { data: user, error, mutate: mutateUser } = useUser(loginContext.accessToken);

  if (error) {
    appContext.addBackendError(error);
  }

  useEffect(() => {
    if (user) {
      if (user.timezone) {
        setTimezone(user.timezone);
      }

      if (user.temperatureFormat) {
        setFahrenheit(user.temperatureFormat === 'fahrenheit');
      }
    }
  }, [user]);

  return (
    <FormControl sx={{ display: 'flex', flexDirection: 'column' }}>
      <Stack direction='column' spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
        <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='columnHeader'>Display temperature as Fahrenheit</Typography>
          <Switch
            checked={fahrenheit}
            onChange={async (event, checked): Promise<void> => {
              if (loginContext.accessToken !== null) {
                setFahrenheit(checked);
                await editUserConfig(
                  { temperatureFormat: checked ? 'fahrenheit' : 'celcius', timezone },
                  loginContext.accessToken,
                  appContext.addBackendError
                );
                await mutateUser();
              }
            }}
            color='info'
          />
        </Stack>
        <Stack direction='column' spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <Typography variant='columnHeader'>The timezone</Typography>
          <Select
            value={timezone}
            sx={{
              height: '40px',
              width: '300px',
              backgroundColor: theme.palette.background.default,
              borderRadius: '5px',
              '&:before': {
                borderRadius: '5px',
              },
            }}
            onChange={async (event: SelectChangeEvent<string>): Promise<void> => {
              if (loginContext.accessToken !== null) {
                setTimezone(event.target.value);
                await editUserConfig(
                  { temperatureFormat: fahrenheit ? 'fahrenheit' : 'celcius', timezone: event.target.value },
                  loginContext.accessToken,
                  appContext.addBackendError
                );
                await mutateUser();
              }
            }}
            MenuProps={{ disablePortal: true }}
          >
            {timeZonesNames.map((timezone, index) => (
              <MenuItem key={index} value={timezone} sx={{ height: '40px' }}>
                {timezone}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
    </FormControl>
  );
}

export default function UserSetting(): JSX.Element {
  const theme = useTheme();

  return (
    <Paper elevation={3} sx={{ display: 'inline-block' }}>
      <Stack
        direction='row'
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          backgroundColor: theme.palette.background.default,
          padding: `${COMPONENT_PADDING - 8}px`,
        }}
      >
        <SettingsIcon fontSize='large' sx={{ color: theme.palette.action.disabled }} />
        <Stack direction='column' sx={{ marginLeft: '1em', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <Typography variant='sectionHeader'>Settings</Typography>
        </Stack>
      </Stack>
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: `${COMPONENT_PADDING}px`, paddingTop: '16px' }}>
        <Settings />
      </Box>
    </Paper>
  );
}
