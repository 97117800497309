import * as React from 'react';
import { useContext, useState } from 'react';
import ComponentBase from '../../common/componentBase/ComponentBase';
import MonthAndYearPicker from '../../../common/monthAndYearPicker/MonthAndYearPicker';
import { utcToZonedTime } from 'date-fns-tz';
import QuantityCalendar from '../dailyQuantityCalendar/QuantityCalendar';
import { AppContext } from '../../../../App';

type FakeDailyQuantityCalendarProps = {
  title: string;
  help?: React.ReactNode;
  fakeData?: number[];
};

export default function FakeDailyQuantityCalendar({ title, help, fakeData }: FakeDailyQuantityCalendarProps): JSX.Element {
  const appContext = useContext(AppContext);

  const date = utcToZonedTime(new Date(), appContext.timezone);

  const [year, setYear] = useState<number>(date.getFullYear());
  const [month, setMonth] = useState<number>(date.getMonth());

  return (
    <ComponentBase title={title} help={help}>
      <MonthAndYearPicker year={year} month={month} onYearSelected={setYear} onMonthSelected={setMonth} />
      {fakeData && <QuantityCalendar month={month} year={year} dailyConnects={fakeData} />}
    </ComponentBase>
  );
}
