import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import BmuOverviewStatus from './BmuOverviewStatus';
import BluOverviewStatus from './BluOverviewStatus';
import BmsOverviewStatus from './BmsOverviewStatus';
import DailyRateGraph from '../../common/DailyRateGraph';
import { COMPONENT_PADDING } from '../../../../themes/theme';
import { SWRResponse } from 'swr';
import { BackendError } from '../../../../utils/BackendError';
import { useBatteryAlarms, useBatteryMinSOC } from '../../../../dataHooks/batteryHooks';
import { BackendDevice, LineChartData } from '../../../../model/backendDataModels';
import AlarmsGraph from '../AlarmsGraph';
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment';
import MonthAndYearPicker from '../../../common/monthAndYearPicker/MonthAndYearPicker';
import { LoginContext } from '../../../../Login';
import { AppContext } from '../../../../App';

type Props = {
  selectedDevice: BackendDevice;
};

export default function BatteryOverviewTab({ selectedDevice }: Props): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const date = utcToZonedTime(new Date(), appContext.timezone);

  const [year, setYear] = useState<number>(date.getFullYear());
  const [month, setMonth] = useState<number>(date.getMonth());

  const startDate = moment(new Date(year, month, 1)).startOf('day');
  const endDate = moment(startDate).add(1, 'month');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
      <Box sx={{ padding: `${COMPONENT_PADDING}px`, height: '100%', overflow: 'auto' }}>
        {selectedDevice.productCategory === 'bmu' ? (
          selectedDevice.type.toLowerCase() === 'blu' ? (
            <BluOverviewStatus selectedDevice={selectedDevice} />
          ) : (
            <BmuOverviewStatus selectedDevice={selectedDevice} />
          )
        ) : (
          <BmsOverviewStatus selectedDevice={selectedDevice} />
        )}
        <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
          History
        </Typography>
        <Grid container spacing={`${COMPONENT_PADDING}px`}>
          <DailyRateGraph
            selector={<MonthAndYearPicker year={year} month={month} onYearSelected={setYear} onMonthSelected={setMonth} />}
            dataHook={(): SWRResponse<LineChartData, BackendError> =>
              useBatteryMinSOC(
                selectedDevice.productCategory === 'bms' ? 'bms' : 'bmu',
                selectedDevice.mui,
                startDate.toDate(),
                endDate.toDate(),
                loginContext.accessToken
              )
            }
            title='Charge Level'
            startDate={startDate}
            endDate={endDate}
          />
          <AlarmsGraph
            type={selectedDevice.productCategory === 'bms' ? 'bms' : 'bmu'}
            mui={selectedDevice.mui}
            dataHook={useBatteryAlarms}
            title='Alarms'
          />
        </Grid>
      </Box>
    </Box>
  );
}
