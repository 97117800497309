import React, { useContext, useEffect, useRef } from 'react';
import { TableRow, TableCell, useTheme, SxProps as MuiSxProps, Chip } from '@mui/material';
import Icon from '../../Icon';
import { isDeviceItem, TreeItemType } from '../../../../model/frontendDataModels';
import moment from 'moment-timezone';
import { BackendDevice } from '../../../../model/backendDataModels';
import { backendDeviceToItem } from '../../../../model/convertModels';
import { AppContext } from '../../../../App';

type DeviceTableRowPops = {
  device: BackendDevice;
  unassigned: boolean;
  wideMode: boolean;
  selectedTreeItems: TreeItemType[];
  setItemSelection: (item: TreeItemType, selected: boolean, event: React.MouseEvent) => void;
};

export default function DeviceTableRow(props: DeviceTableRowPops): JSX.Element | null {
  const theme = useTheme();
  const appContext = useContext(AppContext);

  const selected = props.selectedTreeItems.filter(item => isDeviceItem(item) && item.mui === props.device.mui).length > 0;

  const componentRef = useRef<HTMLTableRowElement>(null);

  const narrowWidths = props.unassigned ? ['30%', '30%', '40%'] : ['40%', '40%', '20%'];
  const wideWidths = props.unassigned ? ['20%', '22%', '18%', '21%', '24%', '24%'] : ['20%', '20%', '20%', '22%', '15%', '15%'];

  const sxBase = theme.typography.deviceInfoText;
  const lastSeenDate = moment(props.device.lastSeen, moment.ISO_8601).tz(appContext.timezone);
  const lastSeen = lastSeenDate.format('YYYY-MM-DD HH:mm');

  useEffect(() => {
    setTimeout(() => {
      if (componentRef.current && selected) {
        componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 500);
  }, [selected, componentRef]);

  const sx: MuiSxProps = {
    ...sxBase,
    border: 'none',
    overflow: 'hidden',
    textOverflow: 'clip',
    userSelect: 'none',
    ...(selected && {
      backgroundColor: theme.palette.background.default,
      fontWeight: 'bold',
    }),
    cursor: 'pointer',
  };
  const warningSx: MuiSxProps = {
    ...sx,
    color: theme.palette.error.main,
    ...(selected && {
      backgroundColor: theme.palette.background.default,
      fontWeight: 'bold',
    }),
  };
  const selectItem = backendDeviceToItem(props.device);

  let updatedBy = props.device.updatedBy ?? '';

  updatedBy = updatedBy.toString();

  if (updatedBy === '') {
    updatedBy = props.device.createdBy.toString();
  }

  // Hide email
  if (updatedBy.includes('@')) {
    updatedBy = '';
  }

  function selectDevice(event: React.MouseEvent): void {
    props.setItemSelection(selectItem, !selected, event);
  }

  return (
    <TableRow ref={componentRef} onClick={selectDevice}>
      <TableCell width={props.wideMode ? wideWidths[0] : narrowWidths[0]} sx={sx}>
        {props.device.deviceId ? `${props.device.deviceId} ` : ' '}
        {props.device.new && <Chip label='new' color='success' variant='outlined' size='small' />}
      </TableCell>
      {props.wideMode && (
        <TableCell width={props.wideMode ? wideWidths[1] : narrowWidths[0]} sx={sx}>
          {props.device.serialNumber}
          {props.unassigned && props.device.new && <Chip label='new' color='success' variant='outlined' size='small' />}
        </TableCell>
      )}
      <TableCell width={props.wideMode ? wideWidths[2] : narrowWidths[1]} sx={sx}>
        {props.device.type}
      </TableCell>
      {props.wideMode && (
        <>
          <TableCell width={wideWidths[3]} sx={sx}>
            {lastSeen}
          </TableCell>
          {!props.unassigned && (
            <TableCell width={wideWidths[4]} sx={sx}>
              {props.device.state}
            </TableCell>
          )}
        </>
      )}
      <TableCell width={props.wideMode ? wideWidths[5] : narrowWidths[2]} sx={props.unassigned ? sx : warningSx}>
        {props.unassigned ? updatedBy : props.device.warning && <Icon name='alarms' />}
      </TableCell>
    </TableRow>
  );
}
