import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import MainMenu from './components/MainMenu';
import { User } from './model/backendDataModels';
import MapView from './components/fleetView/MapView';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { TreeItemType } from './model/frontendDataModels';

type AppRootProps = {
  user?: User;
  selectedTreeItem?: TreeItemType;
  expandItems: (itemIds: string[]) => void;
  helpBox: React.ReactNode;
  helpBoxVisible: boolean;
  setHelpBoxVisible: (visible: boolean) => void;
  setItemSelection: (item: TreeItemType, selected: boolean) => void;
};

export function AppRoot(props: AppRootProps): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const showMap = !location.pathname.includes('arrange');

  useEffect(() => {
    const profileVisible = location.pathname.includes('profile');
    const loadingVisible = location.pathname === '/';

    if (props.user && props.user.acceptedEula) {
      if (loadingVisible || profileVisible) {
        navigate('/view');
      }
    } else {
      if (!loadingVisible) {
        navigate('/');
      }
    }
  }, [props.selectedTreeItem, props.user]);

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        justifyContent: 'flex-start',
        flexWrap: 'nowrap',
        overflow: 'hidden',
      }}
    >
      <MainMenu
        viewRoute={'/view'}
        arrangeRoute={'/arrange'}
        adminRoute={'/admin'}
        profileRoute={'/profile'}
        showHelpBox={props.helpBox ? (): void => props.setHelpBoxVisible(!props.helpBoxVisible) : undefined}
      />
      {props.helpBoxVisible && props.helpBox && <Box>{props.helpBox}</Box>}
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Outlet />
        <Box sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
          {showMap ? (
            <MapView
              showPins={props.user?.acceptedEula === true}
              selectedItem={props.selectedTreeItem}
              expandItems={props.expandItems}
              setItemSelection={props.setItemSelection}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
