import React from 'react';
import { Box } from '@mui/material';
import LoadingIndicator from '../common/LoadingIndicator';

type InitializingViewProps = {
  loading: boolean;
};

export function InitializingView(props: InitializingViewProps): JSX.Element {
  if (props.loading) {
    return (
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 1,
        }}
      >
        <LoadingIndicator
          sx={{
            height: '100%',
            padding: 0,
            margin: 0,
            justifyContent: 'center',
          }}
        />
      </Box>
    );
  } else {
    return <></>;
  }
}
