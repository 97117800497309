import { LanguageType } from '../model/backendDataModels';

export type CommonLogSettings = {
  startDate: Date;
  endDate: Date;
};

export type HistoryChartSettings = {
  startDate: Date;
  endDate: Date;
  leftAxisOptions: string[];
  rightAxisOptions: string[];
};

export type InstantChartSettings = {
  startDate: Date;
  endDate: Date;
  leftAxisOptions: string[];
  rightAxisOptions: string[];
};

export type BatteryReporySettings = {
  selectedDate: Date;
};

export type LogFilesSettings = {
  historyLogSettings?: CommonLogSettings;
  eventLogSettings?: CommonLogSettings;
  protectiveLogSettings?: CommonLogSettings;
  historyChartSettings?: HistoryChartSettings;
  instantChartSettings?: InstantChartSettings;
  batteryReport?: BatteryReporySettings;
};

export type State = {
  language: LanguageType;
  logFilesSettings: LogFilesSettings;
};

type SetLanguage = {
  type: 'SET_LANGUAGE';
  payload: LanguageType;
};

type SetBatteryReportDate = {
  type: 'SET_BATTERY_REPORT_DATE';
  payload: Date;
};

type SetHistoryLogSettings = {
  type: 'SET_HISTORY_LOG_SETTINGS';
  payload?: CommonLogSettings;
};

type SetEventLogSettings = {
  type: 'SET_EVENT_LOG_SETTINGS';
  payload?: CommonLogSettings;
};

type SetProtectiveLogSettings = {
  type: 'SET_PROTECTIVE_LOG_SETTINGS';
  payload?: CommonLogSettings;
};

type SetHistoryChartSettings = {
  type: 'SET_HISTORY_CHART_SETTINGS';
  payload?: HistoryChartSettings;
};

type SetInstantChartSettings = {
  type: 'SET_INSTANT_CHART_SETTINGS';
  payload?: InstantChartSettings;
};

export type Actions =
  | SetLanguage
  | SetBatteryReportDate
  | SetHistoryLogSettings
  | SetEventLogSettings
  | SetProtectiveLogSettings
  | SetHistoryChartSettings
  | SetInstantChartSettings;

export const initialState: State = {
  language: 'English',
  logFilesSettings: {},
};

export function Reducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return {
        ...state,
        language: action.payload,
      };
    case 'SET_BATTERY_REPORT_DATE':
      return {
        ...state,
        logFilesSettings: {
          ...state.logFilesSettings,
          batteryReport: {
            ...state.logFilesSettings.batteryReport,
            selectedDate: new Date(action.payload),
          },
        },
      };
    case 'SET_HISTORY_LOG_SETTINGS':
      return {
        ...state,
        logFilesSettings: {
          ...state.logFilesSettings,
          historyLogSettings: action.payload,
        },
      };
    case 'SET_EVENT_LOG_SETTINGS':
      return {
        ...state,
        logFilesSettings: {
          ...state.logFilesSettings,
          eventLogSettings: action.payload,
        },
      };
    case 'SET_PROTECTIVE_LOG_SETTINGS':
      return {
        ...state,
        logFilesSettings: {
          ...state.logFilesSettings,
          protectiveLogSettings: action.payload,
        },
      };
    case 'SET_HISTORY_CHART_SETTINGS':
      return {
        ...state,
        logFilesSettings: {
          ...state.logFilesSettings,
          historyChartSettings: action.payload,
        },
      };
    case 'SET_INSTANT_CHART_SETTINGS':
      return {
        ...state,
        logFilesSettings: {
          ...state.logFilesSettings,
          instantChartSettings: action.payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
}
