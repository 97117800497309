export type DataObject<Type> = {
  status: 'success' | 'fail';
  data?: Type;
  message?: string;
};

export type CommonOverviewStatus = {
  productType: string;
  voltage: string;
  capacity: string;
  temperature: number;
  fleet: string;
  timestamp: string;
};

export function instanceOfCommonOverviewStatus(object: Record<string, unknown>): object is CommonOverviewStatus {
  return (
    'productType' in object &&
    'voltage' in object &&
    'capacity' in object &&
    'temperature' in object &&
    'fleet' in object &&
    'timestamp' in object
  );
}

export type BmuOverviewStatus = CommonOverviewStatus & {
  state: string;
  soc: string;
  soh: string;
  chargingCurve: string;
  activeAlarms: string;
  deviceId: string;
  warningText: string;
  initialized: boolean;
};

export function instanceOfBmuOverviewStatus(object: Record<string, unknown>): object is BmuOverviewStatus {
  return (
    instanceOfCommonOverviewStatus(object) &&
    'state' in object &&
    'soc' in object &&
    'soh' in object &&
    'chargingCurve' in object &&
    'activeAlarms' in object &&
    'deviceId' in object &&
    'warningText' in object &&
    'initialized' in object
  );
}

export type BluOverviewStatus = CommonOverviewStatus & {
  state: string;
  soc: string;
  soh: string;
  activeAlarms: string;
  deviceId: string;
  warningText: string;
  initialized: boolean;
};

export function instanceOfBluOverviewStatus(object: Record<string, unknown>): object is BluOverviewStatus {
  return (
    instanceOfCommonOverviewStatus(object) &&
    'state' in object &&
    'soc' in object &&
    'soh' in object &&
    'activeAlarms' in object &&
    'deviceId' in object &&
    'warningText' in object &&
    'initialized' in object
  );
}

export type BmsOverviewStatus = CommonOverviewStatus & {
  totalsCycle: number;
  soh: string;
  dischargedAh: string;
  regenAh: string;
  chargedAh: string;
  deepDischarges: number;
  serialNumber: number;
};

export function instanceOfBmsOverviewStatus(object: Record<string, unknown>): object is BmsOverviewStatus {
  return (
    instanceOfCommonOverviewStatus(object) &&
    'totalsCycle' in object &&
    'soh' in object &&
    'dischargedAh' in object &&
    'regenAh' in object &&
    'chargedAh' in object &&
    'deepDischarges' in object &&
    'serialNumber' in object
  );
}

export type ChargerOverviewStatus = CommonOverviewStatus & {
  state: string;
  chargingCurve: string;
  activeAlarms: string;
  deviceId: string;
  warningText: string;
  initialized: boolean;
};

export function instanceOfChargerOverviewStatus(object: Record<string, unknown>): object is ChargerOverviewStatus {
  return (
    instanceOfCommonOverviewStatus(object) &&
    'state' in object &&
    !('soc' in object) &&
    !('soh' in object) &&
    'chargingCurve' in object &&
    'activeAlarms' in object &&
    'deviceId' in object &&
    'warningText' in object &&
    'initialized' in object
  );
}

export type Alarms = {
  alarmNames: string[];
  quantity: number[];
};

export type LanguageType = 'English';
export const languageList: string[] = ['English'];

export type BackendDevice = {
  mui: string;
  fleetId: string;
  customerId: string;
  productCategory: 'gateway' | 'bmu' | 'bms' | 'charger';
  deviceId: string;
  serialNumber: string;
  type: string;
  new: boolean;
  lastSeen: string;
  deviceTag: string;
  createdBy: string | number;
  updatedBy: string | number;
  noOfNodes?: number;
  state?: string;
  warning?: boolean;
  children?: boolean;
};

export type BackendFleet = {
  fleetId: string;
  parentId: string;
  name: string;
  description: string;
  lat: number | null;
  lng: number | null;
  address: string;
  timezone: string;
  fleetGroupChildren: boolean;
  children?: boolean;
};

export type BackendFleetInfo = {
  fleetId: string;
  devices: number;
  bms?: number;
  bmu?: number;
  charger?: number;
  gateway?: number;
};

export type BackendFleetgroupInfo = {
  fleetgroupId: string;
  devices: number;
  bms?: number;
  bmu?: number;
  charger?: number;
  gateway?: number;
};

export function isBackendFleet(object: Record<string, unknown>): object is BackendFleet {
  return (
    'fleetId' in object &&
    'parentId' in object &&
    'name' in object &&
    'description' in object &&
    'lat' in object &&
    'lng' in object &&
    'address' in object &&
    'timezone' in object &&
    'fleetGroupChildren' in object
  );
}

export type BackendFleetParent = {
  fleetgroupId?: string;
  customerId?: string;
  itemId?: string;
  parentId: string;
};

export type BackendFleetLocation = {
  fleetId: string;
  parents: BackendFleetParent[];
  lat: number | null;
  lng: number | null;
  alarms: number;
  gateway: 'none' | 'offline' | 'online';
};

export type BackendFleetgroup = {
  fleetgroupId: string;
  parentId: string;
  name: string;
  description: string;
  fleetGroupChildren: boolean;
  children?: boolean;
};

export function isBackendFleetgroup(object: Record<string, unknown>): object is BackendFleetgroup {
  return 'fleetgroupId' in object && 'parentId' in object && 'name' in object && 'description' in object && 'fleetGroupChildren' in object;
}

export type BackendCustomer = {
  customerId: string;
  parentId: string;
  name: string;
  description: string;
  timezone: string;
  children?: boolean;
};

export function isBackendCustomer(object: Record<string, unknown>): object is BackendCustomer {
  return 'customerId' in object && 'parentId' in object && 'name' in object && 'description' in object && 'timezone' in object;
}

export type LineChartData = {
  yLabel: string; // % or other
  yLabelDisplay: boolean;
  xLabel: string;
  xLabelDisplay: boolean;
  xmin: number;
  xmax: number;
  ymin: number;
  ymax: number;
  values: (number | null)[];
};

export type CalendarStateData = (number | null)[];

export type ListTabs = {
  alarms: boolean;
  soc: boolean;
  equalizing: boolean;
  charging: boolean;
  equalizingCycles: number | null;
  chargingPerWeek: number[] | null;
};

export type FieldsWithSingleValues = {
  fields: Field[];
  values: (string | number)[];
};

export type ConfigData = {
  basic: FieldsWithSingleValues;
  advanced: FieldsWithSingleValues;
  other: FieldsWithSingleValues;
};

type Histogram = {
  xvalues: number[];
  y1values: number[];
  y2values: number[];
};

export type BmsHistograms = {
  temperature: Histogram;
  voltage: Histogram;
  current: Histogram;
};

export type BmsBasicLogInfo = {
  serialNumber: string;
  latestHistoryLogUpdate: string;
  latestConfigLogUpdate: string;
  latestEventLogUpdate: string;
  latestProtectiveLogUpdate: string;
  latestHistogramUpdate: string;
};

export type BasicLogInfo = {
  serialNumber: string;
  latestHistoryLogUpdate: string;
  latestConfigLogUpdate: string;
  latestEventLogUpdate: string;
  latestInstantLogUpdate: string;
  latestStatusUpdate: string;
};

export type Field = {
  name: string;
  type: string;
};

export type FieldsAndValues = {
  fields: Field[];
  values: (string | number)[][];
};

export type FieldsAndNumberValues = {
  fields: Field[];
  values: number[][];
};

export type LogData = {
  basic: FieldsAndValues;
  advanced: FieldsAndValues;
  other: FieldsAndValues;
};

export type HistoryData = {
  _id: string;
  mui: string;
  timestamp: string;
  createdBy: string;
  createdAt: string;
  id5: number;
  id6: number;
  id24: number;
  id25: number;
  id26: number;
  id127: number[];
  id129: string;
  id130: number;
  id133: string;
  id138: number;
  id139: number;
  id140: number;
  id141: number;
  id143: number;
  id144: number;
  id145: number;
  id146: number;
  id147: number;
  id148: number;
  id149: number;
  id150: number;
  id151: number;
  id152: number;
  id153: number;
  id154: number;
  id155: number;
  id156: number;
  id157: number;
  id159: number;
  id160: string;
  id161: string;
  id165: number;
  id166: number;
  id167: number;
  id168: number;
  id169: number;
  id170: number;
  id171: number;
  id172: number;
  id173: number;
  id174: number;
  id175: number;
  id176: number;
  id177: number;
  id178: number;
  id179: number;
  id180: number;
  id181: number;
  id182: number;
  id183: number;
  id184: number;
  id186: number;
  id187: number;
  id220: number[];
};

export function instanceOfLogData(object: Record<string, unknown>): object is LogData {
  return 'basic' in object && 'advanced' in object && 'other' in object;
}

export type ServiceLog = {
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  mui: string;
  eventId: string;
  eventType: string;
  description: string;
  status: string;
};

export type ServiceLogContent = {
  eventType: string;
  description: string;
  status: string;
};

export type ServiceLogCount = {
  count: number;
};

export type AggregatedAlarms = {
  alarmNames: string[];
  quantity: number[];
};

export type LogChart = {
  x: string[];
  y_names: string[];
  type: string[];
  y: number[][];
};

export type LogChartOptions = {
  yaxis1: string[];
  yaxis2: string[][];
};

export const ROLE_ID_INVOICE_ADMIN = 11;
export const ROLE_ID_SYSTEM_ADMIN = 10;

export type PostUser = {
  customerId: string;
  roleId: number;
};

export type User = {
  email: string;
  customerId: string;
  roleId: number;
  acceptedEula: boolean;
  timezone?: string;
  temperatureFormat?: 'fahrenheit' | 'celcius';
};

export type UserData = {
  email: string;
  customerId: string;
  roleId: number;
};

export type UsersData = {
  roles: Record<string, string>;
  users: UserData[];
};

export type BundleSubscription = {
  startDate?: string; // format 'YYYY-MM-DD'
  endDate?: string; // format 'YYYY-MM-DD'
};

export type CustomerInvoiceInfo = {
  customerId: string;
  customerName: string;
  activeDevices: number;
};

export type InvoiceDataInvAdmin = {
  customerId: string;
  customerName: string;
  totalDevices: number;
  customers: CustomerInvoiceInfo[];
  bundles: BundleSubscription[];
};

export type InvoiceDataSysAdmin = InvoiceDataInvAdmin[];

export type MaintenanceMessage = {
  title: string;
  message: string;
};

export type MaintenanceInfo = {
  message: MaintenanceMessage | null;
};

export type SearchCustomer = {
  customerId: string;
  name: string;
  parents: BackendFleetParent[];
};

export type SearchFleetgroup = {
  fleetgroupId: string;
  name: string;
  parents: BackendFleetParent[];
};

export type SearchFleet = {
  fleetId: string;
  name: string;
  parents: BackendFleetParent[];
};

export type SearchDevice = {
  mui: string;
  deviceId: string;
  deviceTag: string;
  serialNumber: string;
  type: string;
  deviceType: string;
  fleetId: string;
  customerId: string;
  parents: BackendFleetParent[];
};

export type SearchResult = {
  customers: SearchCustomer[];
  fleetgroups: SearchFleetgroup[];
  fleets: SearchFleet[];
  devices: SearchDevice[];
  unassignedDevices: SearchDevice[];
};

export type SearchItem = SearchCustomer | SearchFleetgroup | SearchFleet | SearchDevice;
