import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import DeviceHeaderInfo from '../common/DeviceHeaderInfo';
import { CommonOverviewStatus } from '../../../../model/backendDataModels';
import { toTemperatureString } from '../../../../utils/temperatureUtils';
import { AppContext } from '../../../../App';

type Props = {
  status: CommonOverviewStatus;
};

export default function TopRightHeader({ status }: Props): JSX.Element {
  const appContext = useContext(AppContext);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '11px' }}>
      <DeviceHeaderInfo label='Product' text={status.productType.toUpperCase()} />
      <DeviceHeaderInfo label='Voltage' text={`${status.voltage}`} />
      <DeviceHeaderInfo label='Capacity' text={`${status.capacity}`} />
      <DeviceHeaderInfo
        textColor='success'
        label='Temperature'
        text={toTemperatureString(status.temperature, appContext.displayFahrenheit, 2)}
      />
    </Box>
  );
}
