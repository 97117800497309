import React, { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress, Link } from '@mui/material';
import { COMPONENT_PADDING } from '../../themes/theme';
import { LoginContext } from '../../Login';
import { AppContext } from '../../App';
import { BackendError } from '../../utils/BackendError';
import { getFullUrl } from '../../utils/getFullUrl';
import { useUser } from '../../dataHooks/adminHooks';

export default function LegalInformation(): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const { data: user, error: userError, mutate: mutateUser } = useUser(loginContext.accessToken);

  if (userError) {
    appContext.addBackendError(userError);
  }

  const [downloadingPrivacyNotice, setDownloadingPrivacyNotice] = useState(false);
  const [downloadingEula, setDownloadingEula] = useState(false);

  const downloadPrivacyNotice = async (): Promise<void> => {
    const fullUrl = getFullUrl('/legal/document/privacy-notice');

    setDownloadingPrivacyNotice(true);

    try {
      const response = await fetch(fullUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${loginContext.accessToken}`,
        },
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'Privacy notice.pdf');
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);

      link.click();
      link.remove();
    } catch (error) {
      appContext.addBackendError(error as BackendError);
    }

    setDownloadingPrivacyNotice(false);
  };

  const downloadEula = async (): Promise<void> => {
    const fullUrl = getFullUrl('/legal/document/eula');

    setDownloadingEula(true);

    try {
      const response = await fetch(fullUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${loginContext.accessToken}`,
        },
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'EULA.pdf');
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);

      link.click();
      link.remove();
    } catch (error) {
      appContext.addBackendError(error as BackendError);
    }

    setDownloadingEula(false);
  };

  return (
    <Box sx={{ marginTop: `${COMPONENT_PADDING}px`, display: 'flex', flexDirection: 'column' }}>
      <Typography variant='graphHeader'>Legal</Typography>
      <Box sx={{ marginTop: '5px', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ marginTop: '5px', display: 'flex', flexDirection: 'column' }}>
          <Typography variant='columnHeader' sx={{ height: '24px' }}>
            Privacy Notice
          </Typography>
          <Typography variant='tableText' sx={{ height: '24px' }}>
            <Link component='button' onClick={downloadPrivacyNotice}>
              Download
            </Link>
            {downloadingPrivacyNotice && <CircularProgress sx={{ marginLeft: '12px' }} size={12} />}
          </Typography>
        </Box>
        <Box sx={{ marginTop: '5px', display: 'flex', flexDirection: 'column' }}>
          <Typography variant='columnHeader' sx={{ height: '24px' }}>
            End User License Agreement
          </Typography>
          <Typography variant='tableText' sx={{ height: '24px' }}>
            {user?.acceptedEula ? (
              <>
                <Link component='button' onClick={downloadEula}>
                  Download
                </Link>
                {downloadingEula && <CircularProgress sx={{ marginLeft: '12px' }} size={12} />}
              </>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Link
                  component='button'
                  onClick={async (): Promise<void> => {
                    await mutateUser();
                  }}
                >
                  Pending acceptance
                </Link>
              </Box>
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
