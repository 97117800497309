import React, { useContext, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { COMPONENT_PADDING } from '../../../../../themes/theme';
import DeletableTabTitle from '../common/DeletableTabTitle';
import { useAlarmLogs, useAlarmsDaily } from '../../../../../dataHooks/fleetAndFleetgroupHooks';
import { SWRResponse } from 'swr';
import { BackendError } from '../../../../../utils/BackendError';
import AggregatedAlarmsGraph from './AggregatedAlarmsGraph';
import {
  BackendFleet,
  BackendFleetgroup,
  CalendarStateData,
  FieldsAndValues,
  isBackendFleetgroup,
} from '../../../../../model/backendDataModels';
import StateCalendarHelpBox from '../common/dailyStateCalendar/StateCalendarHelpBox';
import { utcToZonedTime } from 'date-fns-tz';
import EventLog from '../common/EventLog';
import Box from '@mui/material/Box';
import moment, { Moment } from 'moment-timezone';
import DailyStateCalendar from '../common/dailyStateCalendar/DailyStateCalendar';
import { LoginContext } from '../../../../../Login';
import { AppContext } from '../../../../../App';

type AlarmsTabProps = {
  selectedFleetOrFleetgroup: BackendFleet | BackendFleetgroup;
  deleteTab: () => Promise<void>;
};

export default function AlarmsTab({ selectedFleetOrFleetgroup, deleteTab }: AlarmsTabProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const date = utcToZonedTime(new Date(), appContext.timezone);

  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const startDate = moment(new Date(year, month, 1)).tz(appContext.timezone).startOf('day');
  const endDate = moment(startDate).add(1, 'month').subtract(1, 'second');
  const today = moment(date);
  const correctedEndDate = moment(endDate).isAfter(today) ? today : endDate;

  const selectedDateStart = selectedDate ? moment(selectedDate).startOf('day') : null;
  const selectedDateEnd = selectedDate ? moment(selectedDate).endOf('day') : null;

  function getAlarmsDailyDataHook(startDate: Moment, endDate: Moment): SWRResponse<CalendarStateData, BackendError> {
    return useAlarmsDaily(
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? 'fleetgroup' : 'fleet',
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? selectedFleetOrFleetgroup.fleetgroupId : selectedFleetOrFleetgroup.fleetId,
      startDate,
      endDate,
      loginContext.accessToken
    );
  }

  function getAlarmLogsDataHook(startDate: Moment, endDate: Moment): SWRResponse<FieldsAndValues, BackendError> {
    return useAlarmLogs(
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? 'fleetgroup' : 'fleet',
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? selectedFleetOrFleetgroup.fleetgroupId : selectedFleetOrFleetgroup.fleetId,
      startDate,
      endDate,
      loginContext.accessToken
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
      <Paper sx={{ padding: `${COMPONENT_PADDING}px` }}>
        <DeletableTabTitle title={'Alarms'} deleteTab={deleteTab} />
        <Grid container spacing={`${COMPONENT_PADDING}px`}>
          <DailyStateCalendar
            dataHook={getAlarmsDailyDataHook}
            title='Days With Alarms'
            help={
              <StateCalendarHelpBox
                success='No alarms triggered'
                warning='At least one non critical alarm triggered'
                error='At least one critical alarm triggered'
              />
            }
            selectedDate={selectedDate}
            onDateSelected={setSelectedDate}
            startDate={startDate}
            endDate={endDate}
            onMonthSelected={(month: number): void => {
              setMonth(month);
              setSelectedDate(null);
            }}
            onYearSelected={(year: number): void => {
              setYear(year);
              setSelectedDate(null);
            }}
          />
          <AggregatedAlarmsGraph
            selectedFleetOrFleetgroup={selectedFleetOrFleetgroup}
            title='Most Common Alarms'
            startDate={selectedDateStart ?? startDate}
            endDate={selectedDateEnd ?? correctedEndDate}
          />
          <EventLog
            dataHook={getAlarmLogsDataHook}
            title='Alarms Log'
            startDate={selectedDateStart ?? startDate}
            endDate={selectedDateEnd ?? correctedEndDate}
          />
        </Grid>
      </Paper>
    </Box>
  );
}
