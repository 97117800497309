import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import ChargerOverviewStatus from './ChargerOverviewStatus';
import { COMPONENT_PADDING } from '../../../../themes/theme';
import DailyQuantityCalendar from '../dailyQuantityCalendar/DailyQuantityCalendar';
import DailyRateGraph from '../../common/DailyRateGraph';
import { useChargerDailyConnects, useChargerUsedMains } from '../../../../dataHooks/chargerHooks';
import { BackendDevice, LineChartData } from '../../../../model/backendDataModels';
import { SWRResponse } from 'swr';
import { BackendError } from '../../../../utils/BackendError';
import { utcToZonedTime } from 'date-fns-tz';
import MonthAndYearPicker from '../../../common/monthAndYearPicker/MonthAndYearPicker';
import moment from 'moment';
import { LoginContext } from '../../../../Login';
import { AppContext } from '../../../../App';

type ChargerOverviewTabProps = {
  selectedDevice: BackendDevice;
};

export default function ChargerOverviewTab({ selectedDevice }: ChargerOverviewTabProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const date = utcToZonedTime(new Date(), appContext.timezone);

  const [year, setYear] = useState<number>(date.getFullYear());
  const [month, setMonth] = useState<number>(date.getMonth());

  const startDate = moment(new Date(year, month, 1)).startOf('day');
  const endDate = moment(startDate).add(1, 'month');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
      <Box sx={{ padding: `${COMPONENT_PADDING}px`, height: '100%', overflow: 'auto' }}>
        <ChargerOverviewStatus selectedDevice={selectedDevice} />
        <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
          History
        </Typography>
        <Grid container spacing={`${COMPONENT_PADDING}px`}>
          <DailyQuantityCalendar mui={selectedDevice.mui} dataHook={useChargerDailyConnects} title='Daily Connects' />
          <DailyRateGraph
            selector={<MonthAndYearPicker year={year} month={month} onYearSelected={setYear} onMonthSelected={setMonth} />}
            dataHook={(): SWRResponse<LineChartData, BackendError> =>
              useChargerUsedMains(selectedDevice.mui, year, month + 1, loginContext.accessToken)
            }
            title='Used Grid Energy'
            bar={true}
            startDate={startDate}
            endDate={endDate}
          />
        </Grid>
      </Box>
    </Box>
  );
}
